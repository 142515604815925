<template>
  <pro-menu-layout class="q-pa-md">
    <pro-deck-card title="Form.Section.MeetingReq" expandable>
      <template #action>
        <pro-button
          v-if="false"
          label="System.Button.EditMeetingRequest"
          :to="editMeeting()"
        />
      </template>
      <template>
        <pro-key-value-table
          :data="data.meetingRequests"
          :rows="meetingReqColumn"
        />
      </template>
    </pro-deck-card>

    <pro-deck-card
      title="Form.Section.MeetingRecord"
      expandable
      style="margin-top: 16px"
    >
      <template #action>
        <pro-button
          v-if="allowSubmitRecord"
          label="System.Button.SubmitRecord"
          icon="PROSmart-Send"
          :to="addMeetingRecord()"
        />
      </template>
      <template>
        <q-card-section
          v-for="(row, index) in data.meetingRequestRecord"
          :key="index"
        >
          <pro-key-value-table :data="row" :rows="meetingRecordColumn" />
          <pro-folders
            class="q-ma-md"
            :folders="getFile(data.recordFiles[index].fileList)"
            show-download-button
          />
        </q-card-section>
      </template>
    </pro-deck-card>
  </pro-menu-layout>
</template>

<script>
import ProFolders from "@/components/PROSmart/ProFolders";
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProButton from "@/components/PROSmart/ProButton";
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable.vue";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard.vue";

export default {
  name: "documentViewMeetingRequestDetail",
  components: {
    ProDeckCard,
    ProKeyValueTable,
    ProButton,
    ProFolders,
    ProMenuLayout,
  },
  props: {
    documentStatus: Number,
  },
  data() {
    return {
      data: {
        purpose: "",
        meetingRequests: [],
        meetingRequestRecord: [],
        recordFiles: [],
        editMeetingWorkflowCode: "",
        addMeetingRecordWorkflowCode: "P_T_RecordOfMeetRes",
      },
      allowSubmitRecordStatus: [50, 80],
      meetingReqColumn: [
        {
          label: "Form.Field.Purpose",
          field: "purpose",
          _style: "width:150px",
        },
        {
          label: "Form.Field.Location",
          field: "location",
          _style: "width:150px",
        },
        {
          label: "Form.Field.TendererName",
          field: "tendererName",
          _style: "width:150px",
        },
        {
          label: "Form.Field.StartDate",
          field: "startDate",
          _style: "width:150px",
        },
        {
          label: "Form.Field.EndDate",
          field: "endDate",
          _style: "width:150px",
        },
      ],
      meetingRecordColumn: [
        {
          label: "Form.Field.SubmittedDate",
          field: "dateCreated",
          _style: "width:150px",
        },
        {
          label: "Form.Field.Remark",
          field: "remark",
          _style: "width:150px",
        },
        {
          label: "Form.Field.NoInvited",
          field: "noOfInvited",
          _style: "width:150px",
        },
        {
          label: "Form.Field.Registered",
          field: "noOfRegistered",
          _style: "width:150px",
        },
        {
          label: "Form.Field.NoAttended",
          field: "noOfAttended",
          _style: "width:150px",
        },
        {
          label: "Form.Col.AttendedTenderers",
          field: "attendedTenderers",
          _style: "width:150px",
        },
      ],
    };
  },

  computed: {
    allowSubmitRecord() {
      return this.allowSubmitRecordStatus.includes(this.documentStatus);
    },
  },

  methods: {
    formatDate(date = new Date()) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "0800"));
    },

    getViewMeetingRequestDetail(meetingId) {
      return {
        name: "DocumentViewMeetingRequestDetail",
        params: {
          meetingId,
        },
      };
    },

    getFile(fileList) {
      return Array.from(fileList, (object) => {
        object.folderDescription = "";
        return object;
      });
    },

    editMeeting() {
      return {
        name: "DocumentMeetingForm",
        params: {
          mode: "Edit",
          code: this.editMeetingWorkflowCode,
          meetingId: this.$route.params.meetingId,
        },
      };
    },

    addMeetingRecord() {
      if (this.purpose === "Tender Briefing") {
        return {
          name: "DocumentMeetingForm",
          params: {
            mode: "Edit",
            code: "P_T_RecordOfTendBr",
          },
        };
      } else if (this.purpose === "Site Visit") {
        return {
          name: "DocumentMeetingForm",
          params: {
            mode: "Edit",
            code: "P_E_RecorOfSiteVisit",
          },
        };
      }
      return {
        name: "DocumentMeetingForm",
        params: {
          mode: "Edit",
          code: "P_T_RecordOfMeetRes",
          meetingId: this.$route.params.meetingId,
        },
      };
    },
  },

  async beforeMount() {
    const { meetingId, id: tenderId } = this.$route.params;
    const res = await this.$proSmart.documentUi.getMeetingRequestById(
      this,
      tenderId,
      meetingId
    );
    const { purpose, location, tendererName, startDate, endDate } = res[0];
    this.purpose = purpose;

    this.data.meetingRequests = {
      purpose,
      location,
      tendererName,
      startDate: this.$proSmart.common.getFormattedDate(startDate),
      endDate: this.$proSmart.common.getFormattedDate(endDate),
    };

    const resRecord = await this.$proSmart.documentUi.getMeetingRecord(
      this,
      tenderId,
      meetingId
    );

    const record = resRecord.map((meetingRecord) => {
      return { meetingRecord };
    });
    record.forEach((input) => {
      this.data.meetingRequestRecord.push({
        dateCreated: this.$proSmart.common.getFormattedDate(
          input.meetingRecord.dateCreated
        ),
        remark: input.meetingRecord.remark,
        noOfInvited: input.meetingRecord.noOfInvited,
        noOfRegistered: input.meetingRecord.noOfRegistered,
        noOfAttended: input.meetingRecord.noOfAttended,
        attendedTenderers:
          input.meetingRecord.attendedTenderers.map((t) => t.name).join("\n") ||
          null,
      });

      this.data.recordFiles.push(input.meetingRecord.recordFile);
    });
  },
};
</script>

<style scoped lang="scss">
.grey-on-hover {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 0;
  }

  &:hover:before {
    opacity: 1;
  }
}
</style>
